
import dayjs from 'dayjs'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  name: 'UserReport',
  setup() {
    const searchMouth = ref(dayjs().startOf('month').subtract(1, 'day').startOf('month'))
    const tableData = ref([])

    const getList = () => {
      const reqData: { year: number; month: number } = {
        year: dayjs(searchMouth.value).year(),
        month: dayjs(searchMouth.value).month() + 1
      }

      axios.get('/-/admin_sms/user/report', { params: reqData }).then(res => {
        const data = res.data.msg
        tableData.value = data.user_reports.filter((it: { SentCount: number }) => it.SentCount)
      })
    }

    const formatTime = (val: string) => {
      return dayjs(val).format('YYYY-MM-DD')
    }

    const formatChannel = (val: string) => {
      try {
        const parse = JSON.parse(val)
        const parsedInfo = []
        const channelMap: { [p: string]: string } = {
          yunxin_noauth: '网易云信',
          senjia_cmpp: '深圳麦穗'
        }

        for (let channelName in parse) {
          const val = parse[channelName]
          if (channelName in channelMap) {
            parsedInfo.push(`${channelMap[channelName]}: ${val}`)
          } else {
            parsedInfo.push(`${channelName}: ${val}`)
          }
        }

        console.log(parse)
        return parsedInfo.join(', ')
      } catch (e) {
        return ''
      }
    }

    const search = () => {
      getList()
    }

    onMounted(() => {
      getList()
    })

    return {
      searchMouth,
      search,
      getList,
      tableData,
      formatTime,
      formatChannel
    }
  }
})
